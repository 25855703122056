import React from 'react';
const Helper = {

    url: window.location.hostname.indexOf("localhost") != -1 ? 'https://system.testzentrumdemo.fybit.de' : 'https://system.'+(window.location.hostname.indexOf("localhost") == -1 ? window.location.hostname : "teststation-bernwardstr.de"),
    suburl: '/api',

    primary: '#e9e9e9',
    secondary: '#D40E3D',
    tertiary: '#eb690b',

    address: [
        {domain: "testcenter-msa.de", address: {map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.235444778725!2d7.711285515891351!3d49.44226687934892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47960d37233fe017%3A0x4764f49b0db623a!2sMerkurstra%C3%9Fe%2062-64%2C%2067663%20Kaiserslautern!5e0!3m2!1sde!2sde!4v1648828753210!5m2!1sde!2sde", street:'Merkurstraße 62-64', zip: '67663', city: 'Kaiserslautern', helper: '(SATURN, Parkplatz)'}},
        {domain: "localhost", address: {map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2451.401865585606!2d10.160188415693142!3d52.09061747571362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a54d7e814673a3%3A0x661712e48173a6b1!2sBindersche%20Str.%205%2C%2031188%20Holle!5e0!3m2!1sde!2sde!4v1638648286785!5m2!1sde!2sde", street: 'Merkurstraße 62-64', zip: '67663', city: 'Kaiserslautern', helper: ''}},
    ].filter(item => item.domain == window.location.hostname.toLowerCase())[0].address,

    opening_times: '07:00 - 20:00 Uhr',
    opening_date: 'Montag-Sonntag',

    booking_times: {
        "from": 6,
        "to": 20,
        "from1": 6,
        "to1": 20,
    }
}
export default Helper;
