import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import axios from "axios";

import Helper from "../components/helper/helper";

const useStyles = theme => ({

});
const classes = useStyles;


class Result extends Component {
    constructor(props) {
        super(props);

        const split = (this.props.location.search.replace("?","").split("&"));
        let lastname = "";
        let testid = "";
        let email = "";
        if(split.length > 0) {
            split.map(y => {
                if(y.indexOf("nachname=") != -1) {
                    lastname = y.replace("nachname=","");
                }
                if(y.indexOf("testid=") != -1) {
                    testid = y.replace("testid=","");
                }
                if(y.indexOf("email=") != -1) {
                    email = y.replace("email=","");
                }
            });
        }
        this.state = {
            error: null,
            isDisconnected: false,
            phone: '',
            email: email,
            email_repeat: '',
            firstname: '',
            lastname: lastname,
            testid: testid,
            file : null
        };




       // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }

    makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }

    render() {
        const {token} = this.state;

        const handleNext = () => {

            this.setState({isLoading: true})

            const headers = {
                //'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            // NOTE Post to HTTPS only in production
            axios.post(Helper.url+Helper.suburl+'/customer/result', {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                testid: this.state.testid,
                email: this.state.email
            }, {
                headers: headers
            }).then((response) => {
                window.open(response.data.file, "_self")
                this.setState({isLoading: false, file: response.data.file})

            }).catch((errormsg) => {
                this.setState({
                    isLoading: false,
                    error: true
                });

            });

        };

        return (
            <Box>

                <Box style={{maxWidth: '1000px', margin: 'auto', marginTop:20, marginBottom: 20}}>
                    <h2>Ergebnis prüfen</h2>

                    <Box style={{padding: 10}}>
                        <p>* Die Test-ID findest du auf deiner Terminbestätigungs E-Mail</p>
                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" fullWidth label="Test ID*" variant="outlined" className={classes.inputField} value={this.state.testid}
                                       onChange={(e) => {this.onChange(e.target.value, 'testid')}}/>
                        </Box>


                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" type={"email"} fullWidth label="E-Mail" variant="outlined" className={classes.inputField} value={this.state.email}
                                       onChange={(e) => {this.onChange(e.target.value, 'email')}}/>
                        </Box>

                        <Box className={"boxViewFull"}>
                            <TextField id="filled-basic" type={"text"} fullWidth label="Nachname" variant="outlined" className={classes.inputField} value={this.state.lastname}
                                       onChange={(e) => {this.onChange(e.target.value, 'lastname')}}/>
                        </Box>

                        <Box style={{clear: 'both'}}></Box>


                        <Box className={"boxViewFull"} style={{width: "1000%"}}>
                            <input type="checkbox" name="testverordnung" id={"testverordnung"} value="1" /> <label for={"testverordnung"}>Hiermit bestätige ich die Konforme Testdurchführung gemäß Testverordnung</label>
                        </Box>
                        <Box style={{clear: 'both'}}></Box>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={"goon"}
                        >
                            Testergebnis anzeigen
                        </Button>
                    </Box>

                    {this.state.error == null ? '': <Box style={{color: 'red', marginTop: 10}}>Ergebnis nicht gefunden</Box>}

                    {this.state.file == null ? '': <Box style={{color: 'green', marginTop: 10}}>Ergebnis gefunden</Box>}
                </Box>
            </Box>
        );
    }
}

export default withStyles(useStyles)(Result);
