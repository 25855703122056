import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import axios from 'axios';
import Helper from "../components/helper/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: Helper.secondary
    },
    btnSubmit: {
        backgroundColor: Helper.secondary,
        color: '#fff'
    }
});
const classes = useStyles;


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,

            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            email: null,
            password: null,
            error: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }



    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    handleSubmit1(){
        //event.preventDefault();
        localStorage.setItem('access_token', "x");
        window.location.href = "/";
    }

    handleSubmit(event){
        event.preventDefault();
        /*this.setState({
            loading: true,
        });*/
        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/auth/login', {
            email: this.state.email,
            password: this.state.password,
        }, {
            headers: headers
        }).then((response) => {
            if(response.status === 200){
                if(response.data.user.employee == 1) {
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('labor', this.state.email.indexOf("admin") != -1 || this.state.email.indexOf("labor") != -1 ? "Ja" : 0);
                    localStorage.setItem('admin', this.state.email.indexOf("admin") != -1 ? "Ja" : 0);
                    window.location.href = "/account";
                }else{
                    alert("nur Zugriff für Mitarbeiter");
                }

               // window.location.href = "/";
            }else{
                //alert(response.status);
            }
        }).catch((errormsg) => {
            console.log(errormsg);
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    loading: false,
                    error: true
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        loading: false,
                        error: true
                    });
                }else{
                    //this.setAlertErrorVisible();
                    console.log("asdasd");
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            }

        });
    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {


            return (
                <div className={"content"}>
                    {
                        error ? <Alert severity="error" disabled={error} onClick={() => {this.setState({error: true});}}>This is an error message!</Alert>: null
                    }
                    <Typography variant="h1" component="h3" gutterBottom>
                        Anmelden
                    </Typography>

                    {window.location.hostname.indexOf("localhost") != -1 || window.location.hostname.indexOf("testzentrum-terminbuchung.de") != -1 ? <div style={{backgroundColor: '#eee', padding: 10}}>
                            Zugangsdaten für den Adminbereich:
                            <br/>
                            E-Mail:  admin@testzentrum-terminbuchung.de<br />
                            Passwort: kfXOsfkTc2
                        </div>: null}

                    <form onSubmit={this.handleSubmit} className={classes.root} noValidate autoComplete="off">
                        <Box style={{width: 400, marginTop: '2%', marginBottom: '5%', alignItems: 'center', justifyContent: 'center'}}>
                            <Box className={classes.boxViewFull}>
                                <TextField id="filled-basic" label="E-Mail" variant="filled" className={classes.inputField} value={this.state.email}
                                           onChange={(e) => {this.onChange(e.target.value.toLowerCase(), 'email')}}/>
                            </Box>

                            <Box className={classes.boxViewFull}>
                                <TextField id="filled-basic" type="password" label="Passwort" variant="filled" className={classes.inputField} value={this.state.password}
                                           onChange={(e) => {this.onChange(e.target.value, 'password')}}/>
                            </Box>

                            <Box className={classes.boxViewFull}>
                                <Button variant="contained" type="submit" fullWidth color="primary" className={classes.btnSubmit}>Anmelden</Button>
                            </Box>

                            <Box className={classes.boxViewFull}>
                                <p>Du bist der Administrator der Teststation?</p>
                                <Button variant="contained" onClick={() => window.location.href = "https://system.testzentrum-terminbuchung.de/admin/login"} type="button" fullWidth color="primary" className={classes.btnSubmit}>Admin-Login</Button>
                            </Box>
                        </Box>
                    </form>
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Login);
