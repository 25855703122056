import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import moment from "moment";
import Helper from "../components/helper/helper";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";

const useStyles = theme => ({

});
const classes = useStyles;


class Userscreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isDisconnected: false,
            token: localStorage.getItem('access_token'),
            email: '',
            email_repeat: '',
            firstname: '',
            lastname: '',
            role: ''
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }
       // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }

    makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }


    render() {
        const {token, test_id, check_id} = this.state;

        const handleNext = () => {

            this.setState({isLoading: true})

            const headers = {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            // NOTE Post to HTTPS only in production
            axios.post(Helper.url+Helper.suburl+'/employee/create', {
                email: this.state.lastname.toLowerCase()+"-"+this.state.role+'@'+window.location.hostname,
                firstname: this.state.firstname,
                lastname: this.state.lastname
            }, {
                headers: headers
            }).then((response) => {
                this.setState({isLoading: false})
                alert("Das Passwort lautet: kfXOsfkTc2");
                window.location.href = "/users";
                //localStorage.setItem('access_token', response.data.access_token);
            }).catch((errormsg) => {
                console.log(errormsg);
                if(typeof errormsg.response == "undefined") {
                    this.setState({
                        isLoading: false,
                        error: true
                    });
                }else{
                    if(errormsg.response.status===404){

                        this.setState({
                            isLoading: false,
                            error: true
                        });
                    }else{
                        //this.setAlertErrorVisible();
                        this.setState({
                            isLoading: false,
                            error: true
                        });
                    }
                }

            });

        };

        const handleChangeCounterUsers = (event) => {
            this.setState({
                role: event.target.value
            });
        };

        return (
            <Box>

                <Box style={{maxWidth: '1000px', margin: 'auto', marginTop:20, marginBottom: 20}}>
                    <h2>Mitarbeiter erstellen</h2>

                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Vorname" variant="outlined" className={classes.inputField} value={this.state.firstname}
                                   onChange={(e) => {this.onChange(e.target.value, 'firstname')}}/>
                    </Box>

                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Nachname" variant="outlined" className={classes.inputField} value={this.state.lastname}
                                   onChange={(e) => {this.onChange(e.target.value, 'lastname')}}/>
                    </Box>

                    <Box className={"boxViewFull"}>
                        <p style={{padding: 0, margin: 0, fontSize: 11}}>Rolle</p>
                        <Select
                            native
                            value={this.state.role}
                            onChange={handleChangeCounterUsers}
                            inputProps={{
                                name: 'person',
                                id: 'age-native-simple',
                            }}
                            className={classes.inputField}
                        >
                            <option value={"admin"}>Admin</option>
                            <option value={"labor"}>Labor</option>
                            <option value={"empfang"}>Empfang</option>
                        </Select>
                    </Box>



                    <Box style={{clear: 'both'}}></Box>


                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={"goon"}
                    >
                        Speichern
                    </Button>

                </Box>
            </Box>
        );
    }
}

export default withStyles(useStyles)(Userscreate);
