import React, { Component } from 'react';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import QrReader from 'react-qr-reader'

import Helper from "../components/helper/helper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


var options = {}

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

    },
    gridList: {
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background: "transparent"
    },

    icon: {
        backgroundColor: "#005D66",
        color: "#fff",
        padding: "5px",
        margin: "12px"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        background: '#fff',
        padding: '20px'
    },
    inputField: {
        width: '100%',
        borderBottom: 0,
        backgroundColor: "#e9e9e9"
    },
    boxView: {
        width: '49%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewFull: {
        width: '100%',
        marginRight: '1%',
        marginBottom: 20
    },
    boxViewsmall: {
        width: '33.5%',
        marginRight: '1%',
    },
    boxViewsmaller: {
        width: '14.5%',
        marginRight: '1%',
    },
    btnAdd: {
        padding: 0,
        margin: 0,
        fontSize: 35,
        width: 35,
        height: 35,
        minHeight: 0,
        minWidth: 0,
        backgroundColor: '#eb690b'
    },
    btnSubmit: {
        backgroundColor: '#eb690b',
        color: '#fff'
    }
});
const classes = useStyles;


class Scanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            token: localStorage.getItem('access_token'),
            isDisconnected: false,
            scriptisLoaded: false,
            innerwidth: window.innerWidth,
            email: null,
            password: null,
            error: false,
            result: 'No result'

        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }



    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

    }

    handleSubmit1(){
        //event.preventDefault();
        localStorage.setItem('access_token', "x");
        window.location.href = "/";
    }

    handleSubmit(event){
        event.preventDefault();
        /*this.setState({
            loading: true,
        });*/
        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/auth/login', {
            email: this.state.email,
            password: this.state.password,
        }, {
            headers: headers
        }).then((response) => {
            if(response.status === 200){
                if(response.data.user.employee == 0) {
                    localStorage.setItem('access_token', response.data.access_token);
                    window.location.href = "/scanner";
                }else{
                    alert("nur Zugriff für Mitarbeiter");
                }

               // window.location.href = "/";
            }else{
                //alert(response.status);
            }
        }).catch((errormsg) => {
            console.log(errormsg);
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    loading: false,
                    error: true
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        loading: false,
                        error: true
                    });
                }else{
                    //this.setAlertErrorVisible();
                    console.log("asdasd");
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            }

        });
    }

    parse(input) {
        var Re1 = /^(version|fn|title|org):(.+)$/i;
        var Re2 = /^([^:;]+);([^:]+):(.+)$/;
        var ReKey = /item\d{1,2}\./;
        var fields = {};

        input.split(/\r\n|\r|\n/).forEach(function (line) {
            var results, key;

            if (Re1.test(line)) {
                results = line.match(Re1);
                key = results[1].toLowerCase();
                fields[key] = results[2];
            } else if (Re2.test(line)) {
                results = line.match(Re2);
                key = results[1].replace(ReKey, '').toLowerCase();

                var meta = {};
                results[2].split(';')
                    .map(function (p, i) {
                        var match = p.match(/([a-z]+)=(.*)/i);
                        if (match) {
                            return [match[1], match[2]];
                        } else {
                            return ["TYPE" + (i === 0 ? "" : i), p];
                        }
                    })
                    .forEach(function (p) {
                        meta[p[0]] = p[1];
                    });

                if (!fields[key]) fields[key] = [];

                fields[key].push({
                    meta: meta,
                    value: results[3].split(';')
                })
            }
        });

        return fields;
    };

    handleScan = data => {
        if (data) {
            if(data.indexOf("http:") != -1 || data.indexOf("https:") != -1 ){
                window.location.href = data;
            }else{
                const daten = this.parse(data);

                const alle = daten.fn.split(" ");
                const vorname = alle[0];
                alle.splice(0,1);
                const name = alle.join(" ");
                let tel = "";
                if(typeof daten.tel[0] != "undefined") {
                    tel = daten.tel[0]["value"][0];
                }
                let street = "";
                let zip = "";
                let city = "";
                if(typeof daten.adr[0] != "undefined") {
                    street = daten.adr[0]["value"][2];
                    zip = daten.adr[0]["value"][5];
                    city = daten.adr[0]["value"][3];
                }


                let link = [vorname, name, street,zip,city, "", ""].join("~")
                window.location.href = window.location.href.replace("/scanner", "")+"/insert?check="+btoa(link);
            }

        }
    }
    handleError = err => {
        console.error(err)
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { error, isLoaded} = this.state;
        const { classes } = this.props;

        const col = (this.state.innerwidth > 900) ? 8 : 2.5;

        if (isLoaded) {
            return <div style={{textAlign: "center", marginTop: "50%"}}>
                <CircularProgress disableShrink />
            </div>
        } else {


            return (
                <div className={"content"}>
                    <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '100%' }}
                    />
                    <p>{this.state.result}</p>
                </div>
            )
        }

    }
}

export default withStyles(useStyles)(Scanner);
