import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import moment from "moment";
import Helper from "../components/helper/helper";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";

const useStyles = theme => ({

});
const classes = useStyles;


class Insert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isDisconnected: false,
            counter_users: 1,
            token: localStorage.getItem('access_token'),
            phone: '',
            email: '',
            email_repeat: '',
            firstname: '',
            lastname: '',
            bday: '',
            street: '',
            zip: '',
            city: '',
            test_id: '',
            check_id: true,
            coronaapp: "keine",
            products: [],
            tests: "",
            ProductList: []
        };

        if(this.state.token == null) {
            window.location.href = "/login";
        }
        if(this.state.token.toString().length < 10) {
            window.location.href = "/login";
        }
       // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log(condition);
        if (condition === 'online') {

        }else{
            this.setState({ isDisconnected: true });
        }
    }

    updateDimensions() {
        this.setState({ innerwidth: window.innerWidth});
    }

    checkUtf(badstring) {
        var fixedstring;
        try{
            // If the string is UTF-8, this will work and not throw an error.
            fixedstring=decodeURIComponent(escape(badstring));
        }catch(e){
            // If it isn't, an error will be thrown, and we can assume that we have an ISO string.
            fixedstring=badstring;
        }

        return fixedstring;
    }

    componentDidMount() {

        this.handleConnectionChange();
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        window.addEventListener("resize", this.updateDimensions.bind(this));

        if(typeof window.location.href.split("?check=")[1] != "undefined") {
            const at = atob(window.location.href.split("?check=")[1]).split('~');

            this.setState({
                firstname: this.checkUtf(at[0]),
                lastname: this.checkUtf(at[1]),
                street: this.checkUtf(at[2]),
                zip: this.checkUtf(at[3]),
                city: this.checkUtf(at[4]),
                phone: at[5],
                bday: at[6],
                email: at[7],
                test_id: (typeof at[8] == "undefined") ? '': at[8],
                check_id: (typeof at[8] == "undefined") ? false:true,
            })

            const headers = {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            if(typeof at[8] != "undefined"){
                axios.get(Helper.url+Helper.suburl+'/booking/check/'+at[8], {
                    headers: headers
                }).then((response) => {

                    window.location.href = "http://"+window.location.hostname+"/que/" + response.data;
                    this.setState({check_id: false})
                }).catch((errormsg) => {
                    this.handleNext();
                    this.setState({check_id: false, test_id: ''})
                });
            }
        }else{
            const headers = {
                //'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            // NOTE Post to HTTPS only in production
            axios.get(Helper.url+Helper.suburl+'/booking/blockedday/2022-01-01?personen='+1, {
                headers: headers
            }).then((response) => {


                this.setState({ProductList: response.data.ProductList,products: response.data.Products}, function() {

                })
            }).catch((errormsg) => {
            });
        }
    }

    onChange(value, key) {
        this.setState( {
            [key]: value
        })
    }

    makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }

    handleNext(){

        this.setState({isLoading: true})

        const headers = {
            //'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        // NOTE Post to HTTPS only in production
        axios.post(Helper.url+Helper.suburl+'/booking/createcustome', {
            userlist: [
                {
                    test_id: this.state.test_id,
                    email: (this.state.email == "") ? this.makeid(5) + "@"+window.location.hostname+"": this.state.email,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    bday: this.state.bday,
                    street: this.state.street,
                    zip: this.state.zip,
                    city: this.state.city,
                    phone: this.state.phone,
                    coronaapp: this.state.coronaapp,
                    tests: this.state.tests
                }
            ],
            selected_day: moment().format("Y-MM-DD"),
            selected_time: moment().hours()+':'+moment().minutes(),
        }, {
            headers: headers
        }).then((response) => {
            this.setState({isLoading: false});

            window.location.href = "https://"+window.location.hostname+"/que/" + response.data[0].test_id;
            //localStorage.setItem('access_token', response.data.access_token);
        }).catch((errormsg) => {
            console.log(errormsg);
            if(typeof errormsg.response == "undefined") {
                this.setState({
                    isLoading: false,
                    error: true
                });
            }else{
                if(errormsg.response.status===404){

                    this.setState({
                        isLoading: false,
                        error: true
                    });
                }else{
                    //this.setAlertErrorVisible();
                    this.setState({
                        isLoading: false,
                        error: true
                    });
                }
            }

        });

    }

    render() {
        const {token, test_id, check_id} = this.state;

        const handleNext = () => {

            this.setState({isLoading: true})

            const headers = {
                //'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };

            // NOTE Post to HTTPS only in production
            axios.post(Helper.url+Helper.suburl+'/booking/createcustome', {
                userlist: [
                    {
                        test_id: this.state.test_id,
                        email: (this.state.email == "") ? this.makeid(5) + "@"+window.location.hostname+"": this.state.email,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        bday: this.state.bday,
                        street: this.state.street,
                        zip: this.state.zip,
                        city: this.state.city,
                        phone: this.state.phone,
                        tests: this.state.tests,
                    }
                ],
                coronaapp: this.state.coronaapp,
                selected_day: moment().format("Y-MM-DD"),
                selected_time: moment().hours()+':'+moment().minutes(),
            }, {
                headers: headers
            }).then((response) => {
                this.setState({isLoading: false})
                window.location.href = "https://"+window.location.hostname+"/que/" + response.data[0].test_id;
                //localStorage.setItem('access_token', response.data.access_token);
            }).catch((errormsg) => {
                console.log(errormsg);
                if(typeof errormsg.response == "undefined") {
                    this.setState({
                        isLoading: false,
                        error: true
                    });
                }else{
                    if(errormsg.response.status===404){

                        this.setState({
                            isLoading: false,
                            error: true
                        });
                    }else{
                        //this.setAlertErrorVisible();
                        this.setState({
                            isLoading: false,
                            error: true
                        });
                    }
                }

            });

        };

        const handleChange4 = (event) => {

            this.setState({coronaapp: event.target.value});
        };

        return (
            <Box>

                <Box style={{maxWidth: '1000px', margin: 'auto', marginTop:20, marginBottom: 20}}>
                    <h2>Direktes Anmelden {
                        check_id ? 'Suche nach Test ...' : (test_id == '') ? null : ' | Test gefunden: ' +test_id
                    }</h2>

                    <Box className={"boxViewFull"} style={{marginTop: 20, swidth: window.innerWidth < 800 ? "100%": "100%"}}>
                        <small style={{fontSize: 12}}>Wählen den Grund deiner Testung:</small>
                        <Select
                            native
                            value={this.state.tests}
                            onChange={(e) => {this.onChange(e.target.value, 'tests')}}
                            inputProps={{
                                name: 'person',
                                id: 'age-native-simple',
                            }}
                        >
                            <option value={""}>Bitte wählen</option>

                            {
                                this.state.ProductList.map(item =>
                                    <option value={item.id}>{item.name}</option>
                                )
                            }


                        </Select>
                    </Box>
                    <Box style={{clear: 'both'}}></Box>
                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Vorname" variant="outlined" className={classes.inputField} value={this.state.firstname}
                                   onChange={(e) => {this.onChange(e.target.value, 'firstname')}}/>
                    </Box>

                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Nachname" variant="outlined" className={classes.inputField} value={this.state.lastname}
                                   onChange={(e) => {this.onChange(e.target.value, 'lastname')}}/>
                    </Box>


                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" type={'text'} fullWidth label="Geburtsdatum" helperText="z.B. 01.01.1980" variant="outlined" className={classes.inputField} value={this.state.bday}
                                   onChange={(e) => {this.onChange(e.target.value, 'bday')}}/>
                    </Box>
                    <Box style={{clear: 'both'}}></Box>
                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Straße & Hausnummer" variant="outlined" className={classes.inputField} value={this.state.street}
                                   onChange={(e) => {this.onChange(e.target.value, 'street')}}/>
                    </Box>

                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="PLZ" variant="outlined" className={classes.inputField} value={this.state.zip}
                                   onChange={(e) => {this.onChange(e.target.value, 'zip')}}/>
                    </Box>

                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Ort" variant="outlined" className={classes.inputField} value={this.state.city}
                                   onChange={(e) => {this.onChange(e.target.value, 'city')}}/>
                    </Box>
                    <Box style={{clear: 'both'}}></Box>
                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" type={"email"} fullWidth label="E-Mail" variant="outlined" className={classes.inputField} value={this.state.email}
                                   onChange={(e) => {this.onChange(e.target.value, 'email')}}/>
                    </Box>

                    <Box className={"boxViewFull"}>
                        <TextField id="filled-basic" fullWidth label="Telefon" variant="outlined" className={classes.inputField} value={this.state.phone}
                                   onChange={(e) => {this.onChange(e.target.value, 'phone')}}/>
                    </Box>

                    <Box style={{clear: 'both'}}></Box>

                    <div>
                        <h3>Möchten Sie das Testergebnis an die Corona-Warn-App übermitteln?</h3>

                        <RadioGroup aria-label="gender" name="gender1" value={this.state.coronaapp} onChange={handleChange4}>
                            <FormControlLabel value="keine" control={<Radio />} label="KEINE DATEN AN DIE CORONA-WARN-APP ÜBERMITTELN" />
                            <FormControlLabel value="anonym" control={<Radio />} label="EINWILLIGUNG ZUR PSEUDONYMISIERTEN ÜBERMITTLUNG (NICHT NAMENTLICH ANZEIGE)" />
                            <p>Hiermit erkläre ich mein Einverständnis zum Übermitteln meines Testergebnisses und meines pseudonymen Coes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona Warn App abrufen kann. Das Testergebnis in der App kann hierbei nicht als namentlicher Testnachweis verwendet werden. Ich habe die Datenschutzhinweise gelesen.</p>
                            <FormControlLabel value="full" control={<Radio />} label="EINWILLIGUNG ZUR PERSONALISIERTEN ÜBERMITTLUNG (NAMENTLICHER TESTNACHWEIS)" />
                            <p>
                                Hiermit erkläre ich mein Einverständnis zum Übermitteln des Testergebnisses und meines pseudonymen Codes an das Serversystem des RKI, damit ich mein Testergebnis mit der Corona Warn App abrufen kann. Ich willige außerdem in die Übermittlung meines Namens und Geburtsdatum an die App ein, damit mein Testergebnis in der App als namentlicher Testnachweis angezeigt werden kann. Ich habe die Datenschutzhinweise gelesen.
                            </p>
                        </RadioGroup>
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={"goon"}
                    >
                        Speichern
                    </Button>

                </Box>
            </Box>
        );
    }
}

export default withStyles(useStyles)(Insert);
